import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import useWindow from "../../Basic/useWindow";
import Loading from "../../Basic/Loading";
import Flex from "../../Basic/Flex";

import notFoundPagePdf from "./not_found_page.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Wrapper = styled(Flex)`
  width: 100vw;
`;
const NotFound = () => {
  const { height } = useWindow();

  return (
    <Wrapper>
      <Helmet>
        <title>Suchst du deinen Arter?</title>
      </Helmet>
      <Document
        file={notFoundPagePdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        loading={<Loading />}
      >
        <Page pageNumber={1} height={height} />
      </Document>
    </Wrapper>
  );
};

export default NotFound;
