import { useCallback, useMemo } from "react";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styled from "styled-components";

import useWindow from "../../Basic/useWindow";
import Loading from "../../Basic/Loading";
import Flex from "../../Basic/Flex";

import Impressum from "./211229_Impressum.pdf";
import CancelFile from "./Icons_cancel.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Wrapper = styled(Flex)`
  width: 100vw;
  margin-top: 30px;
  @media (max-height: 450px) {
    margin-top: 5px;
  }
`;

const Cancel = styled.img`
  width: 40px;
  @media (max-width: 450px) {
    width: 30px;
  }
  @media (max-height: 450px) {
    width: 20px;
  }
  &:hover {
    fill: red;
    cursor: pointer;
  }
  margin-bottom: 40px;
  @media (max-height: 450px) {
    margin-bottom: 10px;
  }
`;
const ImpressumPage = ({ toggleShowImpressum }) => {
  const { width, height } = useWindow();

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      toggleShowImpressum();
    },
    [toggleShowImpressum]
  );

  const impressumSize = useMemo(() => {
    let size = {};
    if (height < 510) {
      size.height = height * 0.66 - 70;
    } else if (width < 600) {
      size.width = width - 40;
    } else {
      size.width = 580;
    }
    return size;
  }, [width, height]);

  return (
    <Wrapper column>
      <Flex onClick={handleClick}>
        <Cancel src={CancelFile} />
      </Flex>
      <Flex>
        <Document
          file={Impressum}
          options={{ workerSrc: "/pdf.worker.js" }}
          loading={<Loading />}
        >
          {impressumSize.width && (
            <Page
              pageNumber={1}
              width={impressumSize.width}
              canvasBackground="transparent"
            />
          )}
          {impressumSize.height && (
            <Page
              pageNumber={1}
              height={impressumSize.height}
              canvasBackground="transparent"
            />
          )}
        </Document>
      </Flex>
    </Wrapper>
  );
};

export default ImpressumPage;
