import { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";

import Flex from "./Flex";
import Button from "./Button/index";
import Insta from "./Button/Insta";

const Wrapper = styled.div`
  height: 100vh
  max-height: -webkit-fill-available;
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  padding: 0;
`;

const Slider = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;
  white-space: nowrap;
  transition: ease 500ms;
  -webkit-transition: ease 500ms;
`;

const Slide = styled.div`
  display: inline-block;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
`;

const Inner = styled(Flex)`
  height: 100%;
  max-height: -webkit-fill-available;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
`;
const Gradient = styled(Flex)`
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  ${(props) =>
    props.top &&
    css`
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 86.98%
      );
      transform: rotate(180deg);
    `}
  ${(props) =>
    !props.top &&
    css`
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
    `}
  margin: 0;
`;

const InnerInner = styled(Flex)`
  max-width: 580px;
  padding: 0 10px;
  left: 0;
  right: 0;
  flex: 1 0 auto;
`;

const ImpressumLink = styled(Flex)`
  color: #a7a7a7;
  text-decoration-line: underline;
  font-weight: 600;
  font-size: 14px;
`;

const LinkWrapper = styled(Flex)`
  margin-top: 8px;
  @media (max-width: 450px) {
    margin-top: 20px;
  }
  @media (max-height: 450px) {
    margin-top: 5px;
  }
  width: 100%;
  font-size: 12pt;
  > * {
    cursor: pointer;
  }
`;

const Buttons = styled(Flex)`
  width: 100%;
`;

//  centered content
// const Img = styled.img`
//   position: absolute;
//   top: -9999px;
//   bottom: -9999px;
//   left: -9999px;
//   right: -9999px;
//   margin: auto;
// `;

const Img = styled.img`
  height: 100vh;
  max-height: -webkit-fill-available;
  top: 0;
  bottom: 0;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const Dot = styled.div`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #c4c4c4;
  border: 1.5px solid #ffffff;
  ${(props) =>
    props.isActive &&
    css`
      background-color: black;
      border: 1.5px solid #ffffff;
    `};
`;

const Dots = styled(Flex)`
  margin: 21px 0 21px 0;
  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
  @media (max-height: 450px) {
    margin: 10px 0 15px 0;
  }
  text-align: center;
  /* gap: 20px; */
  > * {
    margin-right: 20px;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const Slideshow = ({ images, toggleShowImpressum }) => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const stopSlideShow = () => {
    resetTimeout();
  };

  const continueSlideShow = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      4500
    );

    return () => {
      resetTimeout();
    };
  }, [index, images]);

  return (
    <Wrapper onTouchStart={stopSlideShow} onTouchEnd={continueSlideShow}>
      <Slider
        style={{
          transform: `translate3d(${-index * 100}%, 0, 0)`,
          "-webkit-transform": `translate3d(${-index * 100}%, 0, 0)`,
        }}
      >
        {images.map((image, imgIndex) => {
          return (
            <Slide key={imgIndex}>
              <Img src={image} />
            </Slide>
          );
        })}
      </Slider>
      <Gradient top />
      <Gradient />
      <Inner>
        <InnerInner column space align="center" className="wertzu">
          <LinkWrapper center align="center">
            <ImpressumLink onClick={toggleShowImpressum}>Imprint</ImpressumLink>
          </LinkWrapper>
          <Buttons column>
            <Flex>
              <Flex flex={1} />
              <Button />
              <Flex flex={1}>
                <Insta />
              </Flex>
            </Flex>
            <Dots onMouseEnter={stopSlideShow} onMouseLeave={continueSlideShow}>
              {images.map((_, idx) => (
                <Dot
                  key={idx}
                  isActive={index === idx}
                  onClick={() => {
                    setIndex(idx);
                  }}
                ></Dot>
              ))}
            </Dots>
          </Buttons>
        </InnerInner>
      </Inner>
    </Wrapper>
  );
};
export default Slideshow;
