import { useCallback } from "react";
import styled from "styled-components";

import InstaFile from "./src/instagram.svg";
import Flex from "../Flex";

const Insta = styled.img`
  width: 24px;
`;
const Wrapper = styled(Flex)`
  cursor: pointer;
  margin-left: 65px;
`;

const InstaButton = () => {
  const handleClick = useCallback((event) => {
    event.preventDefault();
    window.location = "https://www.instagram.com/rudibutschke/";
  }, []);

  return (
    <Wrapper align="center" center>
      <Insta onClick={handleClick} src={InstaFile} />
    </Wrapper>
  );
};
export default InstaButton;
