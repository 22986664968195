import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Helmet } from "react-helmet";

import Access from "./Access";
import Admin from "./Admin";
import Login from "./Basic/Login";
import Page404 from "./Pages/404";
import LandingPage from "./Pages/Landing";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ich könnte dein Arter sein.</title>
        <link rel="shortcut icon" href="/211229_Favicon_48x48.png" />
        <link rel="apple-touch-icon" href="/211229_Favicon_48x48.png" />
      </Helmet>
      <Router>
        <Switch>
          <Route path={process.env.REACT_APP_ADMIN_ROUTE || "/admin"}>
            <Admin />
          </Route>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/error">
            <Page404 />
          </Route>
          <Route path="/:token">
            <Access />
          </Route>
          <Route>
            <Page404 />
          </Route>
        </Switch>
      </Router>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

export default App;
