import { useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Impressum from "../Impressum";
import Slider from "../../Basic/Slider";

import image1 from "./slides/Slider_1.jpg";
import image2 from "./slides/Slider_2.jpg";
import image3 from "./slides/Slider_3.jpg";
import image4 from "./slides/Slider_4.jpg";
import image5 from "./slides/Slider_5.jpg";

const Wrapper = styled.div`
  position: relative;
`;

const ImpressumWrapper = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: calc(0.66 * 100vh);
  max-height: calc(0.66 * -webkit-fill-available);
  background: rgba(255, 255, 255, 0.9);
`;

const StyledImpressum = styled(Impressum)``;

const LandingPage = () => {
  const [showImpressum, setShowImpressum] = useState(false);

  const toggleShowImpressum = useCallback(() => {
    setShowImpressum((prevShow) => !prevShow);
  }, []);

  return (
    <Wrapper>
      <Slider
        toggleShowImpressum={toggleShowImpressum}
        images={[image1, image2, image3, image4, image5]}
      />
      {showImpressum && (
        <ImpressumWrapper>
          <Helmet>
            <title>Impressum - Ich bin dein Arter.</title>
          </Helmet>
          <StyledImpressum toggleShowImpressum={toggleShowImpressum} />
        </ImpressumWrapper>
      )}
    </Wrapper>
  );
};
export default LandingPage;
