import {
  useMemo,
  useState,
  useRef,
  createRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { useQuery } from "react-query";
import _ from "lodash";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Flex from "../../Basic/Flex";
import Loading from "../../Basic/Loading";
import { getFileAccess } from "../../Basic/api";
import useWindow from "../../Basic/useWindow";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ResponsivePDF = styled(Document)`
  width: 100vw;
`;

const Pdf = ({ url }) => {
  let history = useHistory();

  const { width } = useWindow();
  const [numPages, setNumPages] = useState(0);

  const refs = useMemo(
    () =>
      new Array(numPages)
        .fill()
        .reduce((acc, _, index) => ({ ...acc, [index + 1]: createRef() }), {}),
    [numPages]
  );

  // useLayoutEffect(() => {
  //   if (scrollPosition && height) {
  //     setTimeout(() => {
  //       window.scrollTo({
  //         top: scrollPosition * (width * height),
  //         left: 0,
  //         behavior: "smooth",
  //       });
  //     }, 1000);
  //   }
  // }, [scrollPosition, height, width]);

  // const onScroll = useCallback(() => {
  //   const position =
  //     window.scrollY ||
  //     document.body.scrollTop ||
  //     document.documentElement.scrollTop;
  //   const relativeScaledPosition = position / (width * height);
  //   setScrollPosition(relativeScaledPosition);
  // }, [setScrollPosition, height, width]);

  // useLayoutEffect(() => {
  //   document.addEventListener("scroll", onScroll, true);
  //   return () => document.removeEventListener("scroll", onScroll, true);
  // });

  const onItemClick = useCallback(
    ({ pageNumber }) => {
      if (pageNumber) {
        refs[pageNumber].current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    [refs]
  );

  const handleLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  const redirectOnError = useCallback(() => {
    history.push("/error");
  }, [history]);

  return (
    <ResponsivePDF
      file={url}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadError={redirectOnError}
      onSourceError={redirectOnError}
      onLoadSuccess={handleLoadSuccess}
      onItemClick={onItemClick}
      loading={<Loading />}
      // renderMode="svg"
    >
      {_.map(refs, (_, index) => (
        <div ref={refs[index]} key={index}>
          <Page pageNumber={parseInt(index)} width={width} loading={<></>} />
        </div>
      ))}
    </ResponsivePDF>
  );
};

const PdfFile = ({ id, token }) => {
  let history = useHistory();

  // let scrollPosition = useRef(null);
  // const setScrollPosition = useCallback(
  //   (newPosition) => {
  //     scrollPosition.current = { [id]: newPosition };
  //   },
  //   [id]
  // );

  const { isLoading, error, data } = useQuery(
    `pdf_${id}`,
    getFileAccess({ documentId: id, token }),
    {
      refetchOnWindowFocus: false,
      onError: () => history.push("/error"),
    }
  );

  const url = useMemo(() => {
    if (data) {
      const blob = new Blob([data], { type: "application/pdf" });
      return { url: URL.createObjectURL(blob) };
    }
  }, [data]);

  if (isLoading || (!url && !error)) {
    return <Loading />;
  }

  if (error) {
    return <Flex>An error has occurred: {error?.message}</Flex>;
  }

  if (data?.error) {
    switch (data.error) {
      case "Internal Server Error":
        return (
          <Flex>
            <h2>{data.error}</h2>
          </Flex>
        );
      default:
        return (
          <Flex>
            <h2>Token is {data.error}.</h2>
          </Flex>
        );
    }
  }

  return (
    url && (
      <Pdf
        url={url}
        // scrollPosition={scrollPosition.current?.[id]}
        // setScrollPosition={setScrollPosition}
      />
    )
  );
};

export default PdfFile;
