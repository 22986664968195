import { useCallback } from "react";
import styled from "styled-components";

import Flex from "../Flex";

const Back = styled(Flex)`
  cursor: pointer;
  width: 160px;
  height: 40px;
  background: #ff0000;
  box-shadow: 0px 0px 12px 4px rgba(255, 255, 255, 0.5);
  border-radius: 84px;
`;

const Label = styled(Flex)`
  color: white;
  font-weight: 600;
  font-size: 14px;
  ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
`;

const Button = () => {
  const handleClick = useCallback((event) => {
    event.preventDefault();
    window.location = "mailto:fuchs@ichbindeinarter.de";
  }, []);

  return (
    <Back onClick={handleClick}>
      <Flex space>
        <Label>
          <Flex column>Get in touch</Flex>
        </Label>
      </Flex>
    </Back>
  );
};
export default Button;
